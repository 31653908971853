* {
  box-sizing: border-box;
}
body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  min-width: 100vw;
  font-family: 'Roboto', sans-serif;
  color: $black;
  font-weight: 400;
}

.header {
  display: flex;
  flex-wrap: wrap;
  padding: 2.5rem 3rem 0.5rem 3.5rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;

  h1 {
    font-size: 2rem;
    font-weight: 500;
    margin-right: 1rem;
  }
  .actions {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    a {
      color: $black;
      padding: 0.75rem 1rem;
      text-decoration: none;
      // background-color: rgba($black, 0.2);
      border: 1px solid $black;
      margin: 0.5rem 0 0.5rem 1rem;
      border-radius: $radius;
      font-size: 1.2rem;

      &:first-of-type {
        margin-left: 0;
      }
      &:hover {
        background-color: $black; // rgba($black, 0.4);
        color: $white;
      }
    }
  }

  @include breakpoint(600px) {
    padding: 2rem 0 0 0;

    h1, .actions {
      width: 100%;
    }
    h1 {
      text-align: center;
      margin: 0 0 1rem;
    }
    .actions {

      a {
        margin: 0 0.5rem !important;
      }
    }
  }
}
.content {
  width: 100vw;
  height: 100vh;
  background-color: $white;
  position: absolute;
  left: 0;
  top: 0;
  box-shadow: inset 0 0 0 2rem $black;

  img {
    // width: 100%;
    // max-width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
  }

  @include breakpoint(600px) {
    box-shadow: inset 0 0 0 1rem $black;
  }
}
.footer {
  padding: 1rem;
  width: 100%;
  display: flex;
}
